import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Typography, Drawer } from 'antd'
import { BellOutlined } from '@ant-design/icons'
import { MANAGE_INDEX_PATHNAME } from '../router'
import styles from './Home.module.scss'

// import axios from 'axios'
// import '../_mock/index.ts'

const { Title, Paragraph } = Typography

const UpdateDrawer: FC = () => {
  const [visible, setVisible] = useState(true)

  return (
    <>
      <Button
        type="text"
        icon={<BellOutlined />}
        onClick={() => setVisible(true)}
        style={{
          position: 'fixed',
          left: '20px',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 100,
        }}
      />
      <Drawer
        title="🎉 0.1.12 版本更新公告"
        placement="left"
        onClose={() => setVisible(false)}
        open={visible}
        width={350}
        mask={false}
        className={styles.updateDrawer}
      >
        <div>
          <h3 style={{ marginBottom: '16px', color: '#1890ff' }}>2024.11.03 新版本更新内容</h3>
          <ul style={{ lineHeight: '2em' }}>
            <li>✨ 新增问卷模板功能</li>
            <li>✨ 页面设置--新增IP地址限制功能</li>
            <li>✨ 问卷提交界面--新增允许提交后查看已填答卷功能</li>
            <li>🚀 优化编辑器性能</li>
            {/* <li>🎨 新增IP地址限制功能</li> */}
            {/* <li>📊 新增查看已填答卷功能</li> */}
            <li>🔧 修复&ldquo;样式代码&rdquo;和&ldquo;脚本代码&rdquo;不生效的问题</li>
          </ul>
          <div style={{ marginTop: '20px', color: '#666', fontSize: '14px' }}>
            后续会参照
            <a href="https://www.wjx.cn/app/themehtml/plan.aspx" target="_blank" rel="noreferrer">
              问卷星
            </a>
            的会员功能进行优化 如果您有需求或建议，请填写
            <a
              href="http://suncz.com:3000/question/6726a64cab2b2587098408e7"
              target="_blank"
              rel="noreferrer"
            >
              反馈表单
            </a>
            <br />
            <br />
            参与开发或部署您自己的问卷系统，请联系
            <a href="mailto:2081043891@qq.com" target="_blank" rel="noreferrer">
              邮箱:
            </a>
            2081043891@qq.com
            <br />
            <br />
            感谢您的使用,我们会继续努力提供更好的服务!
          </div>
        </div>
      </Drawer>
    </>
  )
}

const Home: FC = () => {
  const nav = useNavigate()

  return (
    <div className={styles.container}>
      <UpdateDrawer />
      <div className={styles.info}>
        <Title>问卷调查 | 在线投票</Title>
        <Paragraph>没有任何会员或广告，操作简单极速发布</Paragraph>
        <div>
          <Button type="primary" onClick={() => nav(MANAGE_INDEX_PATHNAME)}>
            开始使用
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Home
