import React from 'react'
import { Spin } from 'antd'
import useLoadUserData from '../hooks/useLoadUserData'

export function withLoadUserData<P extends object>(WrappedComponent: React.ComponentType<P>) {
  const WithLoadUserDataComponent = (props: P) => {
    const { waitingUserData, loadUserData } = useLoadUserData()

    React.useEffect(() => {
      loadUserData()
    }, [])

    if (waitingUserData) {
      return (
        <div style={{ textAlign: 'center', marginTop: '60px' }}>
          <Spin />
        </div>
      )
    }

    return <WrappedComponent {...props} />
  }

  // 在路由配置中使用时，需要返回 React 元素而不是组件
  return <WithLoadUserDataComponent {...({} as P)} />
}
