import { useCallback, useEffect, useState } from 'react'
import { useRequest } from 'ahooks'
import { useDispatch } from 'react-redux'
import useGetUserInfo from './useGetUserInfo'
import { getUserInfoService } from '../services/user'
import { loginReducer } from '../store/userReducer'
import { removeToken } from '../utils/user-token'

interface ApiError extends Error {
  response?: {
    status: number
  }
}

function useLoadUserData() {
  const dispatch = useDispatch()
  const [waitingUserData, setWaitingUserData] = useState(true)

  // ajax 加载用户信息
  const { run } = useRequest(getUserInfoService, {
    manual: true,
    onSuccess(result) {
      const { username, nickname } = result
      dispatch(loginReducer({ username, nickname })) // 存储到 redux store
    },
    onError(error: ApiError) {
      if (error.response && error.response.status === 401) {
        // Token 无效或过期，清除本地存储的 token
        removeToken()
      }
    },
    onFinally() {
      setWaitingUserData(false)
    },
  })

  // 判断当前 redux store 是否已经存在用户信息
  const { username } = useGetUserInfo() // redux store
  useEffect(() => {
    if (username) {
      setWaitingUserData(false) // 如果 redux store 已经存在用户信息，就不用重新加载了
      return
    }
    run() // 如果 redux store 中没有用户信息，则进行加载
  }, [username])
  const loadUserData = useCallback(() => {
    if (username) {
      console.log('redux store 中已存在用户信息，不重新加载')
      setWaitingUserData(false)
    } else {
      console.log('redux store 中不存在用户信息，重新加载')
      run()
    }
  }, [username, run])
  return { waitingUserData, loadUserData }
}

export default useLoadUserData
