import React from 'react'
import { RouterProvider } from 'react-router-dom'
import routerConfig from './router'
import 'antd/dist/reset.css'
import './App.css'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

function App() {
  const user = useSelector((state: any) => state.user)

  useEffect(() => {
    console.log('全局用户状态更新:', user)
  }, [user])

  return <RouterProvider router={routerConfig}></RouterProvider>
}

export default App
