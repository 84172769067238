import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

export type UserStateType = {
  username: string
  nickname: string
}

const INIT_STATE: UserStateType = { username: '', nickname: '' }

export const userSlice = createSlice({
  name: 'user',
  initialState: INIT_STATE,
  reducers: {
    loginReducer: (state: UserStateType, action: PayloadAction<UserStateType>) => {
      return action.payload // 设置 username nickname 到 redux store
      // 用不到 immer
    },
    logoutReducer: () => INIT_STATE,
    setUserInfo: (state, action) => {
      console.log('Setting user info:', action.payload)
      state.username = action.payload.username
      state.nickname = action.payload.nickname
    },
  },
})

export const { loginReducer, logoutReducer, setUserInfo } = userSlice.actions

export const loginReducerAsync = createAsyncThunk(
  'user/login',
  async (userData: { username: string; nickname: string }, { dispatch }) => {
    console.log('Login reducer called with:', userData)
    dispatch(setUserInfo(userData))
    return userData
  }
)

export default userSlice.reducer
